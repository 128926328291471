<template>
  <b-container fluid>
    <b-row class="vh-100">
      <slot :show-sidebar="showSidebar" name="sidebar"></slot>
      <b-col :class="{'d-none': showSidebar}" class="d-md-block content ml-sm-auto" cols="12" md="9" xl="10">
        <slot></slot>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  mounted () {
    this.$root.$on('sidebar::toggle', () => {
      this.showSidebar = !this.showSidebar
    })
    this.$root.$on('sidebar::set', (value) => {
      this.showSidebar = value
    })
  },
  data () {
    return {
      showSidebar: false
    }
  },
  watch: {
    '$route' () {
      this.$root.$emit('sidebar::set', false)
    }
  }
}
</script>

<style lang="scss">
  .content {
    padding: 1.5 * 16px + 44px 0 0;
    min-height: 100%;
  }
</style>
