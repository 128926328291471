import Vue from 'vue'
import VueRouter from 'vue-router'
import includes from 'lodash/includes'
import Container from '../views/Dashboard'
import Selector from '../views/Selector'
import NotFound from '../views/NotFound'
import permissions from '../permissions.json'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: {
      render: c => c('router-view')
    },
    children: [
      {
        path: '',
        meta: { auth: true },
        component: Selector
      }, {
        path: 'energy',
        component: Container,
        children: [
          {
            path: '',
            name: 'dash.index',
            meta: { auth: true, can: permissions.CAN_READ_ENERGY_CUSTOMERS },
            component: () => import('../views/EnergyDashboard.vue')
          }, {
            path: 'reports',
            component: {
              render: c => c('router-view')
            },
            children: [
              {
                path: '',
                name: 'energy.reports.list',
                meta: { auth: true, can: permissions.CAN_READ_USERS, title: 'Reports' },
                component: () => import('../views/Reports/Index.vue')
              }, {
                path: 'payments',
                name: 'energy.reports.payments',
                meta: { auth: true, can: permissions.CAN_READ_USERS, title: 'Payments Report' },
                component: () => import('../views/Reports/Payments.vue')
              }
            ]
          }, {
            path: 'contracts',
            component: () => import(/* webpackChunkName: "energy.contracts" */ '../views/Contracts/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'energy.contracts.list',
                meta: { auth: true, can: permissions.CAN_READ_CONTRACTS, title: 'Contracts' },
                component: () => import(/* webpackChunkName: "energy.contracts" */ '../views/Contracts/List.vue')
              }, {
                path: 'create',
                name: 'energy.contracts.create',
                meta: { auth: true, can: permissions.CAN_WRITE_CONTRACTS, title: 'Create Contract' },
                component: () => import(/* webpackChunkName: "energy.contracts" */ '../views/Contracts/Upsert.vue')
              }, {
                path: ':id',
                name: 'energy.contracts.update',
                meta: { auth: true, can: permissions.CAN_READ_CONTRACTS, title: 'Update Contract' },
                component: () => import(/* webpackChunkName: "energy.contracts" */ '../views/Contracts/Upsert.vue')
              }
            ]
          }, {
            path: 'customers',
            component: () => import(/* webpackChunkName: "energy.customers" */ '../views/Customers/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'energy.customers.list',
                meta: { auth: true, can: permissions.CAN_READ_ENERGY_CUSTOMERS, title: 'Energy Customers' },
                props: { showLeads: false },
                component: () => import(/* webpackChunkName: "energy.customers" */ '../views/Customers/List.vue')
              }, {
                path: 'create',
                name: 'energy.customers.create',
                meta: { auth: true, can: permissions.CAN_WRITE_ENERGY_CUSTOMERS, title: 'Create Energy Customer' },
                props: { showLeads: false },
                component: () => import(/* webpackChunkName: "energy.customers" */ '../views/Customers/Upsert.vue')
              }, {
                path: ':id',
                name: 'energy.customers.update',
                meta: { auth: true, can: permissions.CAN_READ_ENERGY_CUSTOMERS, title: 'Update Energy Customer' },
                props: { showLeads: false },
                component: () => import(/* webpackChunkName: "energy.customers" */ '../views/Customers/Upsert.vue')
              }
            ]
          }, {
            path: 'leads',
            component: () => import(/* webpackChunkName: "energy.customers" */ '../views/Customers/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'energy.leads.list',
                meta: { auth: true, can: permissions.CAN_READ_ENERGY_CUSTOMERS, title: 'Leads' },
                props: { showLeads: true },
                component: () => import(/* webpackChunkName: "energy.customers" */ '../views/Customers/List.vue')
              }, {
                path: 'create',
                name: 'energy.leads.create',
                meta: { auth: true, can: permissions.CAN_WRITE_ENERGY_CUSTOMERS, title: 'Create Lead' },
                props: { showLeads: true },
                component: () => import(/* webpackChunkName: "energy.customers" */ '../views/Customers/Upsert.vue')
              }, {
                path: ':id',
                name: 'energy.leads.update',
                meta: { auth: true, can: permissions.CAN_READ_ENERGY_CUSTOMERS, title: 'Update Lead' },
                props: { showLeads: true },
                component: () => import(/* webpackChunkName: "energy.customers" */ '../views/Customers/Upsert.vue')
              }
            ]
          }, {
            path: 'meters',
            component: () => import(/* webpackChunkName: "energy.meters" */ '../views/Meters/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'energy.meters.list',
                meta: { auth: true, can: permissions.CAN_READ_METERS, title: 'Meters' },
                component: () => import(/* webpackChunkName: "energy.meters" */ '../views/Meters/List.vue')
              }, {
                path: 'create',
                name: 'energy.meters.create',
                meta: { auth: true, can: permissions.CAN_WRITE_METERS, title: 'Create Meter' },
                component: () => import(/* webpackChunkName: "energy.meters" */ '../views/Meters/Upsert.vue')
              }, {
                path: ':id',
                name: 'energy.meters.update',
                meta: { auth: true, can: permissions.CAN_READ_METERS, title: 'Update Meter' },
                component: () => import(/* webpackChunkName: "energy.meters" */ '../views/Meters/Upsert.vue')
              }
            ]
          }, {
            path: 'payments',
            component: () => import(/* webpackChunkName: "energy.payments" */ '../views/Payments/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'energy.payments.list',
                meta: { auth: true, can: permissions.CAN_READ_PAYMENTS, title: 'Payments' },
                component: () => import(/* webpackChunkName: "energy.payments" */ '../views/Payments/List.vue')
              }
            ]
          }, {
            path: 'sites',
            component: () => import(/* webpackChunkName: "energy.sites" */ '../views/Sites/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'energy.sites.list',
                meta: { auth: true, can: permissions.CAN_READ_ENERGY_SITES, title: 'Energy Sites' },
                component: () => import(/* webpackChunkName: "energy.sites" */ '../views/Sites/List.vue')
              }, {
                path: 'create',
                name: 'energy.sites.create',
                meta: { auth: true, can: permissions.CAN_WRITE_ENERGY_SITES, title: 'Create Energy Site' },
                component: () => import(/* webpackChunkName: "energy.sites" */ '../views/Sites/Upsert.vue')
              }, {
                path: ':id',
                name: 'energy.sites.update',
                meta: { auth: true, can: permissions.CAN_READ_ENERGY_SITES, title: 'Update Energy Site' },
                component: () => import(/* webpackChunkName: "energy.sites" */ '../views/Sites/Upsert.vue')
              }
            ]
          }, {
            path: 'suppliers',
            component: () => import(/* webpackChunkName: "energy.suppliers" */ '../views/Suppliers/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'energy.suppliers.list',
                meta: { auth: true, can: permissions.CAN_READ_SUPPLIERS, title: 'Suppliers' },
                component: () => import(/* webpackChunkName: "energy.suppliers" */ '../views/Suppliers/List.vue')
              }, {
                path: 'create',
                name: 'energy.suppliers.create',
                meta: { auth: true, can: permissions.CAN_WRITE_SUPPLIERS, title: 'Create Supplier' },
                component: () => import(/* webpackChunkName: "energy.suppliers" */ '../views/Suppliers/Upsert.vue')
              }, {
                path: ':id',
                name: 'energy.suppliers.update',
                meta: { auth: true, can: permissions.CAN_READ_SUPPLIERS, title: 'Update Supplier' },
                component: () => import(/* webpackChunkName: "energy.suppliers" */ '../views/Suppliers/Upsert.vue')
              }
            ]
          }, {
            path: 'pricing',
            component: () => import('../views/Pricing.vue')
          }, {
            path: '/404',
            alias: '*',
            component: NotFound
          }
        ]
      }, {
        path: 'security',
        component: Container,
        children: [
          {
            path: '',
            name: 'security.dash.index',
            meta: { auth: true, can: permissions.CAN_VIEW_DASHBOARD }
          }, {
            path: 'reports',
            component: {
              render: c => c('router-view')
            },
            children: [
              {
                path: '',
                name: 'security.reports.list',
                meta: { auth: true, can: permissions.CAN_READ_USERS, title: 'Reports' },
                component: () => import('../views/SecReports/Index.vue')
              }, {
                path: 'commission',
                name: 'security.reports.commission',
                meta: { auth: true, can: permissions.CAN_READ_USERS, title: 'Commission Report' },
                component: () => import('../views/SecReports/Commission.vue')
              }, {
                path: 'profit',
                name: 'security.reports.profit',
                meta: { auth: true, can: permissions.CAN_READ_USERS, title: 'Profit Report' },
                component: () => import('../views/SecReports/Profit.vue')
              }
            ]
          }, {
            path: 'customers',
            component: () => import(/* webpackChunkName: "security.customers" */ '../views/SecCustomers/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'security.customers.list',
                meta: { auth: true, can: permissions.CAN_READ_SECURITY_CUSTOMERS },
                component: () => import(/* webpackChunkName: "security.customers" */ '../views/SecCustomers/List.vue')
              }, {
                path: 'create',
                name: 'security.customers.create',
                meta: { auth: true, can: permissions.CAN_WRITE_SECURITY_CUSTOMERS },
                component: () => import(/* webpackChunkName: "security.customers" */ '../views/SecCustomers/Upsert.vue')
              }, {
                path: ':id',
                name: 'security.customers.update',
                meta: { auth: true, can: permissions.CAN_READ_SECURITY_CUSTOMERS },
                component: () => import(/* webpackChunkName: "security.customers" */ '../views/SecCustomers/Upsert.vue')
              }
            ]
          }, {
            path: 'inventory',
            component: () => import(/* webpackChunkName: "security.inventory" */ '../views/Inventory/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'security.inventory.list',
                meta: { auth: true, can: permissions.CAN_READ_INVENTORY },
                component: () => import(/* webpackChunkName: "security.inventory" */ '../views/Inventory/List.vue')
              }, {
                path: 'assign',
                name: 'security.inventory.assign',
                meta: { auth: true, can: permissions.CAN_READ_INVENTORY },
                component: () => import(/* webpackChunkName: "security.inventory" */ '../views/Inventory/AssignQuantity.vue')
              }, {
                path: 'create',
                name: 'security.inventory.create',
                meta: { auth: true, can: permissions.CAN_WRITE_INVENTORY },
                component: () => import(/* webpackChunkName: "security.inventory" */ '../views/Inventory/Upsert.vue')
              }, {
                path: ':id(\\d+)',
                name: 'security.inventory.update',
                meta: { auth: true, can: permissions.CAN_READ_INVENTORY },
                component: () => import(/* webpackChunkName: "security.inventory" */ '../views/Inventory/Upsert.vue')
              }
            ]
          }, {
            path: 'sites',
            component: () => import(/* webpackChunkName: "security.sites" */ '../views/SecSites/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'security.sites.list',
                meta: { auth: true, can: permissions.CAN_READ_SECURITY_SITES },
                component: () => import(/* webpackChunkName: "security.sites" */ '../views/SecSites/List.vue')
              }, {
                path: 'create',
                name: 'security.sites.create',
                meta: { auth: true, can: permissions.CAN_WRITE_SECURITY_SITES },
                component: () => import(/* webpackChunkName: "security.sites" */ '../views/SecSites/Upsert.vue')
              }, {
                path: ':id',
                name: 'security.sites.update',
                meta: { auth: true, can: permissions.CAN_READ_SECURITY_SITES },
                component: () => import(/* webpackChunkName: "security.sites" */ '../views/SecSites/Upsert.vue')
              }
            ]
          }
        ]
      }, {
        path: 'admin',
        component: Container,
        children: [
          {
            path: '',
            name: 'admin.dash.index',
            meta: { auth: true, can: permissions.CAN_VIEW_DASHBOARD }
          }, {
            path: 'audits',
            component: () => import(/* webpackChunkName: "admin.audits" */ '../views/Audits/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'admin.adits.list',
                meta: { auth: true, can: permissions.CAN_READ_SECURITY_SITES },
                component: () => import(/* webpackChunkName: "admin.audits" */ '../views/Audits/List.vue')
              }
            ]
          }, {
            path: 'roles',
            component: () => import(/* webpackChunkName: "admin.roles" */ '../views/Roles/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'admin.roles.list',
                meta: { auth: true, can: permissions.CAN_READ_INVENTORY },
                component: () => import(/* webpackChunkName: "admin.roles" */ '../views/Roles/List.vue')
              }, {
                path: 'create',
                name: 'admin.roles.create',
                meta: { auth: true, can: permissions.CAN_WRITE_INVENTORY },
                component: () => import(/* webpackChunkName: "admin.roles" */ '../views/Roles/Upsert.vue')
              }, {
                path: ':id',
                name: 'admin.roles.update',
                meta: { auth: true, can: permissions.CAN_READ_INVENTORY },
                component: () => import(/* webpackChunkName: "admin.roles" */ '../views/Roles/Upsert.vue')
              }
            ]
          }, {
            path: 'users',
            component: () => import(/* webpackChunkName: "admin.users" */ '../views/Users/Wrapper.vue'),
            children: [
              {
                path: '',
                name: 'admin.users.list',
                meta: { auth: true, can: permissions.CAN_READ_SECURITY_CUSTOMERS },
                component: () => import(/* webpackChunkName: "admin.users" */ '../views/Users/List.vue')
              }, {
                path: 'create',
                name: 'admin.users.create',
                meta: { auth: true, can: permissions.CAN_WRITE_SECURITY_CUSTOMERS },
                component: () => import(/* webpackChunkName: "admin.users" */ '../views/Users/Upsert.vue')
              }, {
                path: ':id',
                name: 'admin.users.update',
                meta: { auth: true, can: permissions.CAN_READ_SECURITY_CUSTOMERS },
                component: () => import(/* webpackChunkName: "admin.users" */ '../views/Users/Upsert.vue')
              }
            ]
          }
        ]
      }
    ]
  }, {
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Authentication/Container.vue'),
    children: [
      {
        path: 'not-allowed',
        name: 'auth.not-allowed',
        meta: { auth: true },
        component: () => import(/* webpackChunkName: "auth" */ '../views/Authentication/NotAllowed.vue')
      }, {
        path: 'login',
        name: 'auth.login',
        meta: { auth: false },
        component: () => import(/* webpackChunkName: "auth" */ '../views/Authentication/Login.vue')
      }, {
        path: 'logout',
        name: 'auth.logout',
        meta: { auth: true },
        component: () => import(/* webpackChunkName: "auth" */ '../views/Authentication/Logout.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const permsKey = 'authentication/userPermissions'
router.beforeEach((to, from, next) => {
  if (to.matched.some(r => r.meta.auth)) {
    if (from.matched.some(r => r.meta.auth)) {
      if (!to.meta.can || includes(store.getters[permsKey], to.meta.can.apiname)) {
        return next()
      }

      return next({ path: '/403' })
    }

    store.dispatch('authentication/check')
      .then(response => {
        if (to.matched.some(r => r.meta.can)) {
          if (includes(store.getters[permsKey], to.meta.can.apiname)) {
            return next()
          }

          return next({ path: '/403' })
        }
        return next()
      })
      .catch(() =>
        next({
          name: 'auth.login',
          query: { next: from.path }
        })
      )
  } else if (to.matched.some(r => !r.meta.auth)) {
    if (from.matched.some(r => !r.meta.auth)) {
      return next()
    } else {
      store.dispatch('authentication/check')
        .then(() => next({ name: 'dash.index' }))
        .catch(() => next())
    }
  } else {
    next()
  }
})

export default router
