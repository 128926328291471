<template>
  <b-container class="bg-selector d-flex flex-column justify-content-center vh-100" fluid>
    <b-row>
      <b-col class="text-center" cols="12" md="6" offset-md="3">
        <b-row class="justify-content-center">
          <b-col class="my-3" cols="12" md="4" v-if="shouldShowEnergySelector">
            <b-button class="font-weight-bold d-flex flex-column justify-content-center p-3 h-100" to="/energy" variant="primary"><img class="mx-auto" style="object-fit: contain;" height="100" width="100" src="../assets/energy-logo.png" alt="A2L Energy"></b-button>
          </b-col>
          <b-col class="my-3" cols="12" md="4" v-if="shouldShowSecuritySelector">
            <b-button class="font-weight-bold d-flex flex-column justify-content-center p-3 h-100" to="/security" variant="primary"><img class="mx-auto" style="object-fit: contain;" height="100" width="100" src="../assets/security-logo.png" alt="MSS Security"></b-button>
          </b-col>
          <b-col class="my-3" cols="12" md="4" v-if="shouldShowAdminSelector">
            <b-button class="font-weight-bold d-flex flex-column justify-content-center p-3 h-100" to="/admin" variant="primary"><img class="mx-auto" style="object-fit: contain;" height="100" width="100" src="../assets/logo.png" alt="Admin"></b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import permissions from '../permissions.json'
import currentUser from '../mixins/currentUser'
export default {
  mixins: [currentUser],
  mounted () {
    if (this.shouldShowEnergySelector && !(this.shouldShowSecuritySelector && this.shouldShowAdminSelector)) {
      this.$router.push({ name: 'dash.index' })
    }
    if (this.shouldShowSecuritySelector && !(this.shouldShowEnergySelector && this.shouldShowAdminSelector)) {
      this.$router.push({ name: 'security.dash.index' })
    }
    if (this.shouldShowAdminSelector && !(this.shouldShowEnergySelector && this.shouldShowSecuritySelector)) {
      this.$router.push({ name: 'admin.dash.index' })
    }
  },
  computed: {
    shouldShowEnergySelector () {
      return [
        permissions.CAN_READ_ENERGY_CUSTOMERS,
        permissions.CAN_READ_ENERGY_SITES,
        permissions.CAN_READ_METERS,
        permissions.CAN_READ_CONTRACTS,
        permissions.CAN_READ_PAYMENTS,
        permissions.CAN_READ_SUPPLIERS
      ].map(this.currentUserCan).indexOf(true) > -1
    },
    shouldShowSecuritySelector () {
      return [
        permissions.CAN_READ_SECURITY_CUSTOMERS,
        permissions.CAN_READ_SECURITY_SITES,
        permissions.CAN_READ_INVENTORY
      ].map(this.currentUserCan).indexOf(true) > -1
    },
    shouldShowAdminSelector () {
      return [
        permissions.CAN_READ_USERS
      ].map(this.currentUserCan).indexOf(true) > -1
    }
  }
}
</script>

<style lang="scss" scoped>
  .bg-selector {
    background: lighten($primary, 10%);
  }
</style>
