<template>
  <div>
    <Header/>
    <Container>
      <template #sidebar>
        <Sidebar />
      </template>
      <transition name="fade" mode="out-in">
        <router-view :class="{ 'my-4': $route.path !== '/energy/pricing' }" />
      </transition>
    </Container>
  </div>
</template>

<script>
import Container from '../components/Container'
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'

export default {
  components: {
    Container,
    Sidebar,
    Header
  }
}
</script>

<style>

</style>
