<template>
  <div class="notifs">
    <slot></slot>
    <div class="d-none d-md-flex flex-column justify-content-end notifs-area p-4 vh-100">
      <transition-group name="fade" mode="out-in">
        <b-card v-for="(notification, $index) in sortedNotifications" :class="`border-${notification.variant || 'dark'} ${notification.dark ? 'bg-dark text-white' : ''}`" class="mt-4 shadow" :key="`notif-${$index}`" :sub-title="notification.subtitle" :title="notification.title">
          <div class="close" @click="notifications.splice(notifications.indexOf(notification), 1)">&times;</div>
          <b-card-text :class="{'mb-0': !notification.buttonLayout}" v-if="notification.text">{{ notification.text }}</b-card-text>
          <div class="d-flex flex-row">
            <div v-for="(button, $buttonIndex) in notification.buttonLayout" :class="{'mr-1': notification.buttonLayout.length - 1 !== $buttonIndex, 'flex-fill': !Array.isArray(button)}" :key="`notif-${$index}-button-${$buttonIndex}`">
              <b-button block :variant="button.variant" v-if="!Array.isArray(button)" v-b-popover.hover.top="button.popover"><b-icon :class="{'mr-2': button.text}" :icon="button.icon" v-if="button.icon"></b-icon>{{ button.text }}</b-button>
              <div class="d-flex flex-row" v-if="Array.isArray(button)">
                <div v-for="(button, $buttonIndex) in button" :class="{'mr-1': notification.buttonLayout.length - 1 !== $buttonIndex, 'flex-fill': !Array.isArray(button)}" :key="`notif-${$index}-button-${$buttonIndex}`">
                  <b-button block :variant="button.variant" v-if="!Array.isArray(button)" v-b-popover.hover.top="button.popover"><b-icon :class="{'mr-2': button.text}" :icon="button.icon" v-if="button.icon"></b-icon>{{ button.text }}</b-button>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </transition-group>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy'

export default {
  computed: {
    sortedNotifications () {
      return orderBy(this.notifications, 'ts', 'desc')
    }
  },
  data () {
    return {
      notifications: []
    }
  },
  mounted () {
    this.$root.$on('new-notification', ({ buttonLayout, timeout, title, text, subtitle, variant }) => {
      // Check the validity of buttons before adding them to the eventual
      // notification - buttons without text OR icon will be dismissed. Buttons
      // will have a default variant of dark.
      if (buttonLayout) {
        buttonLayout = buttonLayout.map(btn => btn.text || btn.icon ? ({ icon: btn.icon, text: btn.text, popover: btn.popover, variant: btn.variant || 'dark' }) : null).filter(x => x !== null)
      }

      const notification = { buttonLayout, title, text, subtitle, variant }
      this.notifications.push(notification)
      setTimeout(() => {
        this.notifications.splice(this.notifications.indexOf(notification), 1)
      }, timeout || 5000)
    })
  }
}
</script>

<style lang="scss" scoped>
  .notifs {
    .notifs-area {
      position: fixed;
      top: 0; right: 0;
      pointer-events: none;
      max-width: 500px;
      z-index: 998 !important;
      width: 100%;
    }

    .card {
      pointer-events: initial;
      position: relative;

      &.bg-dark {
        h6.card-subtitle.text-muted.mb-2 {
          color: darken($light, 15%) !important;
        }
      }

      .close {
        cursor: pointer;
        position: absolute;
        top: .2rem; right: .7rem;
      }
    }
  }
</style>
