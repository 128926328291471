<template>
  <b-navbar fixed="top" type="dark" variant="primary">
    <b-navbar-brand @click="$root.$emit('sidebar::toggle')" class="d-block d-md-none">
      <b-icon class="text-white mr-2" font-scale="1.3" icon="layout-sidebar-inset"></b-icon>
    </b-navbar-brand>
    <b-navbar-brand @click="$router.go(-1)">
      <b-icon class="text-white mr-2" font-scale="1.3" icon="arrow-left"></b-icon>
    </b-navbar-brand>
    <b-navbar-brand to="/energy" v-if="$route.path.indexOf('/energy') > -1">
      <img style="object-fit: contain; max-width: 25vw;" alt="A2L Energy" height="42" src="../assets/energy-logo.png" />
      <span class="sr-only">A2L Energy</span>
    </b-navbar-brand>
    <b-navbar-brand to="/security" v-if="$route.path.indexOf('/security') > -1">
      <img style="object-fit: contain; max-width: 25vw;" alt="MSS Security" height="42" src="../assets/security-logo.png" />
      <span class="sr-only">MSS Security</span>
    </b-navbar-brand>
    <b-navbar-brand to="/admin" v-if="$route.path.indexOf('/admin') > -1">
      <img style="object-fit: contain; max-width: 25vw;" alt="Aim 2 Learn Admin" height="42" src="../assets/logo.png" />
      <span class="sr-only">Aim 2 Learn Admin</span>
    </b-navbar-brand>
    <b-navbar-nav class="ml-auto">
      <b-nav-item-dropdown right variant="light">
        <template v-slot:button-content>
          <span class="text-light">{{ [currentUser.forename, currentUser.surname].join(' ') }}</span>
        </template>
        <b-dropdown-item to="/">Change Tenant</b-dropdown-item>
        <b-dropdown-item :to="{name: 'auth.logout'}">Sign Out</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import currentUser from '../mixins/currentUser'

export default {
  mixins: [currentUser]
}
</script>

<style>

</style>
