import Vue from 'vue'
import Vuex from 'vuex'

import authentication from './authentication'
import scheduledCalls from './scheduledCalls'
import reporting from './reporting'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authentication,
    reporting,
    scheduledCalls
  }
})
