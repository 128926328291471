const namespaced = true

export default {
  namespaced,
  state: () => ({
    callReport: {
      loading: false,
      fields: [],
      items: []
    }
  }),
  actions: {
    callReport ({ commit }, { refresh = false }) {
      commit('callReport/request')
      return window.axios.get('/report/calls', { params: { refresh } })
        .then(req => {
          commit('callReport/success', req)
          return req
        })
        .catch(err => {
          commit('callReport/failure', err)
          return err
        })
    }
  },
  getters: {
    callReportFields ({ callReport }) {
      return callReport.fields
    },
    callReportItems ({ callReport }) {
      return callReport.items
    },
    callReportLoading ({ callReport }) {
      return callReport.loading
    }
  },
  mutations: {
    'callReport/request' (state) {
      state.callReport.loading = true
    },
    'callReport/success' (state, response) {
      state.callReport.loading = false
      state.callReport.fields = response.data.fields
      state.callReport.items = response.data.items
    },
    'callReport/failure' (state) {
      state.callReport.loading = false
      state.callReport.fields = []
      state.callReport.items = []
    }
  }
}
