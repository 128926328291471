<template>
  <b-col :class="{'d-flex': showSidebar, 'd-none': !showSidebar}" class="bg-light d-md-flex sidebar" cols="12" md="3" xl="2">
    <div class="mh-100 sidebar-scroll">
      <b-nav class="mt-4 mb-4" vertical v-if="$route.path.indexOf('/energy') > -1">
        <b-nav-item class="text-dark" to="/energy" exact-active-class="active"><b-icon class="mr-2" font-scale="1.4" icon="grid-1x2" /> Dashboard</b-nav-item>
      </b-nav>

      <b-nav class="mb-4" vertical v-if="$route.path.indexOf('/energy') > -1">
        <b-nav-text class="nav-header">Energy</b-nav-text>
        <b-nav-item class="text-dark" to="/energy/leads" active-class="active" v-if="currentUserCan(permissions.CAN_READ_ENERGY_CUSTOMERS)"><b-icon class="mr-2" font-scale="1.4" icon="person" /> Leads</b-nav-item>
        <b-nav-item class="text-dark" to="/energy/customers" active-class="active" v-if="currentUserCan(permissions.CAN_READ_ENERGY_CUSTOMERS)"><b-icon class="mr-2" font-scale="1.4" icon="person-check" /> Customers</b-nav-item>
        <b-nav-item class="text-dark" to="/energy/sites" active-class="active" v-if="currentUserCan(permissions.CAN_READ_ENERGY_SITES)"><b-icon class="mr-2" font-scale="1.4" icon="building" /> Sites</b-nav-item>
        <b-nav-item class="text-dark" to="/energy/meters" active-class="active" v-if="currentUserCan(permissions.CAN_READ_METERS)"><b-icon class="mr-2" font-scale="1.4" icon="lightning" /> Meters</b-nav-item>
        <b-nav-item class="text-dark" to="/energy/contracts" active-class="active" v-if="currentUserCan(permissions.CAN_READ_CONTRACTS)"><b-icon class="mr-2" font-scale="1.4" icon="bookmark-check" /> Contracts</b-nav-item>
        <b-nav-item class="text-dark" to="/energy/payments" active-class="active" v-if="currentUserCan(permissions.CAN_READ_PAYMENTS)"><b-icon class="mr-2" font-scale="1.4" icon="credit-card" /> Payments</b-nav-item>
      </b-nav>
      <b-nav class="mb-4" vertical v-if="$route.path.indexOf('/security') > -1">
        <b-nav-text class="nav-header">Security</b-nav-text>
        <b-nav-item class="text-dark" to="/security/customers" active-class="active" v-if="currentUserCan(permissions.CAN_READ_SECURITY_CUSTOMERS)"><b-icon class="mr-2" font-scale="1.4" icon="people" /> Customers</b-nav-item>
        <b-nav-item class="text-dark" to="/security/sites" active-class="active" v-if="currentUserCan(permissions.CAN_READ_SECURITY_SITES)"><b-icon class="mr-2" font-scale="1.4" icon="building" /> Sites</b-nav-item>
      </b-nav>
      <b-nav class="mb-4" vertical v-if="$route.path.indexOf('/admin') > -1">
        <b-nav-text class="nav-header">Admin</b-nav-text>
        <b-nav-item class="text-dark" to="/admin/audits" active-class="active"><b-icon class="mr-2" font-scale="1.4" icon="shield-check" /> Audits</b-nav-item>
        <b-nav-item class="text-dark" to="/admin/roles" active-class="active"><b-icon class="mr-2" font-scale="1.4" icon="person-check" /> Permissions &amp; Roles</b-nav-item>
        <b-nav-item class="text-dark" to="/admin/users" active-class="active" v-if="currentUserCan(permissions.CAN_READ_USERS)"><b-icon class="mr-2" font-scale="1.4" icon="people" /> Users</b-nav-item>
      </b-nav>
      <b-nav class="mb-4" vertical>
        <b-nav-text class="nav-header" v-if="$route.path.indexOf('/admin') === -1">Miscellaneous</b-nav-text>
        <b-nav-item class="text-dark" to="/energy/suppliers" active-class="active" v-if="$route.path.indexOf('/energy') > -1 && currentUserCan(permissions.CAN_READ_SUPPLIERS)"><b-icon class="mr-2" font-scale="1.4" icon="box-seam" /> Suppliers</b-nav-item>
        <b-nav-item class="text-dark" to="/security/inventory" active-class="active" v-if="$route.path.indexOf('/security') > -1 && currentUserCan(permissions.CAN_READ_INVENTORY)"><b-icon class="mr-2" font-scale="1.4" icon="box-seam" /> Inventory</b-nav-item>
        <b-nav-item class="text-dark" to="/energy/reports" exact-active-class="active" v-if="$route.path.indexOf('/energy') > -1 && currentUserCan(permissions.CAN_VIEW_ENERGY_PAYMENT_REPORT)"><b-icon class="mr-2" font-scale="1.4" icon="bar-chart" /> Reporting</b-nav-item>
        <b-nav-item class="text-dark" to="/energy/pricing" exact-active-class="active" v-if="$route.path.indexOf('/energy') > -1 && currentUserCan(permissions.VIEW_PRICING_TAB)"><b-icon class="mr-2" font-scale="1.4" icon="arrow-up-right-square" /> Pricing</b-nav-item>
        <b-nav-item class="text-dark" to="/security/reports" exact-active-class="active"  v-if="$route.path.indexOf('/security') > -1 && (currentUserCan(permissions.CAN_VIEW_SECURITY_COMMISSION_REPORT) || currentUserCan(permissions.CAN_VIEW_SECURITY_PROFIT_REPORT))"><b-icon class="mr-2" font-scale="1.4" icon="bar-chart" /> Reporting</b-nav-item>
      </b-nav>
    </div>
  </b-col>
</template>

<script>
import permissions from '../permissions.json'
import currentUser from '../mixins/currentUser'

export default {
  mixins: [currentUser],
  data () {
    return { permissions, showSidebar: false }
  },
  mounted () {
    this.$root.$on('sidebar::toggle', () => {
      this.showSidebar = !this.showSidebar
    })
    this.$root.$on('sidebar::set', (value) => {
      this.showSidebar = value
    })
  }
}
</script>

<style lang="scss" scoped>
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 68px 0 0;
    max-height: 100%;
    z-index: 100;
    border-right: 1px solid darken($light, 10%);

    .sidebar-scroll {
      overflow-y: scroll;
      width: 100%;
    }

    ul.nav {
      li.nav-header {
        color: lighten($dark, 20%);
        font-size: 0.8rem;
        font-weight: bold;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
      }

      li.nav-item {
        text-overflow: ellipsis;
        width: 100%;

        a {
          color: $dark;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;

          svg {
            color: lighten($dark, 10%);
            margin-top: -.2em;
          }

          &:not(.active):hover {
            background: darken($light, 5%);
          }

          &.active {
            background: $primary;
            color: $light;

            svg {
              color: $light;
            }
          }
        }
      }
    }
  }

  @media (max-width: 765px) {
    .sidebar {
      border-right: 0;
    }
  }
</style>
