import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import axios from 'axios'

import App from './App'
import router from './router'
import store from './store'

Vue.use(BootstrapVueIcons)
Vue.use(BootstrapVue)

Vue.use({
  install: (Vue, options) => {
    Vue.prototype.$notify = function () {
      return {
        notify: function (options) {
          if (options.buttonLayout && !(options.buttonLayout instanceof Array)) {
            throw new Error('buttonLayout must be instance of Array')
          }

          this.$root.$emit('new-notification', options)
        }.bind(this)
      }
    }
  }
})

window.axios = axios
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL
window.axios.defaults.headers.Accept = 'application/json'
window.axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.withCredentials = true

Vue.config.productionTip = process.env.NODE_ENV === 'production'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// For debugging
window._ = require('lodash')
