<template>
<b-container class="py-5">
  <b-row>
    <b-col class="text-center" cols="12" md="6" offset-md="3">
      <b-icon class="display-2 mb-4 text-secondary" icon="cloud-slash"></b-icon>
      <p class="font-weight-bold display-4 mb-2">Not Found</p>
      <p class="h5 mb-4">Apologies, we could not find what you're looking for...</p>
      <b-button to="/"><b-icon icon="chevron-left"></b-icon> Go Home</b-button>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
export default {

}
</script>

<style>

</style>
