import includes from 'lodash/includes'
import permissions from '../permissions.json'
import { mapCurrentUser, mapUserPerms } from '../utils/store'

export default {
  computed: {
    ...mapCurrentUser(),
    ...mapUserPerms()
  },
  created () {
    this.permissions = permissions
  },
  methods: {
    currentUserCan (permission) {
      return includes(this.userPermissions, permission.apiname)
    }
  }
}
