import {
  AUTH_CHECK_REQUEST,
  AUTH_CHECK_SUCCESS,
  AUTH_CHECK_FAILURE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  XSRF_TOKEN_REQUEST,
  XSRF_TOKEN_SUCCESS,
  XSRF_TOKEN_FAILURE
} from './types'

export default {
  [AUTH_CHECK_REQUEST] (state) {
    state.current = { ...state.current, perms: [], user: null }
    state.login = { ...state.login, loading: true, errors: {} }
  },
  [AUTH_CHECK_SUCCESS] (state, { perms, user }) {
    state.current = { ...state.current, perms, user }
    state.login = { ...state.login, loading: false }
  },
  [AUTH_CHECK_FAILURE] (state) {
    state.login.loading = false
  },
  [AUTH_LOGIN_REQUEST] (state) {
    state.current = { ...state.current, perms: [], user: null }
    state.login = { ...state.login, loading: true, errors: {} }
  },
  [AUTH_LOGIN_SUCCESS] (state, { perms, user }) {
    state.current = { ...state.current, perms, user }
    state.login = { ...state.login, loading: false }
  },
  [AUTH_LOGIN_FAILURE] (state, { errors }) {
    state.login = { ...state.login, loading: false, errors }
  },
  [AUTH_LOGOUT_REQUEST] (state) {
    state.current = { ...state.current, perms: [], user: null }
    state.logout = { ...state.logout, loading: true }
  },
  [AUTH_LOGOUT_SUCCESS] (state) {
    state.current = { ...state.current, perms: [], user: null }
    state.logout = { ...state.logout, loading: false }
  },
  [AUTH_LOGOUT_FAILURE] (state, error) {
    state.logout = { ...state.logout, loading: false, error }
  },
  [XSRF_TOKEN_REQUEST] (state) {
    state.xsrf = { ...state.xsrf, loading: true, token: null }
  },
  [XSRF_TOKEN_SUCCESS] (state) {
    state.xsrf = { ...state.xsrf, loading: false, token: true }
  },
  [XSRF_TOKEN_FAILURE] (state, { errors }) {
    state.xsrf = { ...state.xsrf, loading: false, errors, token: false }
  }
}
