export default {
  currentUser ({ current }) {
    return current.user || {}
  },
  isLoading ({ login, logout, xsrf }) {
    return login.loading || logout.loading || xsrf.loading
  },
  isLoggingIn ({ login }) {
    return login.loading
  },
  isLoggingOut ({ logout }) {
    return logout.loading
  },
  loginErrors ({ login }) {
    return login.errors || {}
  },
  logoutError ({ logout }) {
    return logout.errors || {}
  },
  userPermissions ({ current }) {
    return current.perms || []
  },
  xsrfTokenLoaded ({ xsrf }) {
    return xsrf.token
  }
}
