import get from 'lodash/get'

const namespaced = true

export default {
  namespaced,
  state: () => ({
    completing: false,
    creating: false,
    loading: false,
    data: {}
  }),
  actions: {
    reset ({ commit }) {
      commit('fetchData/success', { data: {} })
    },
    fetchData ({ commit }, params = {}) {
      commit('fetchData/request')
      return window.axios.get('/scheduled-calls', { params })
        .then(req => {
          commit('fetchData/success', req)
          return req
        })
        .catch(err => {
          commit('fetchData/failure', err)
          return err
        })
    },
    createCall ({ commit }, payload) {
      commit('createCall/request')
      return window.axios.post('/scheduled-calls', payload)
        .then(req => {
          commit('createCall/success', req)
          return req
        })
        .catch(err => {
          commit('createCall/failure', err)
          return err
        })
    },
    completeCall ({ commit }, { id, payload }) {
      commit('completeCall/request')
      return window.axios.put(`/scheduled-calls/${id}`, payload)
        .then(req => {
          commit('completeCall/success', req)
          return req
        })
        .catch(err => {
          commit('completeCall/failure', err)
          return err
        })
    }
  },
  getters: {
    completing ({ completing }) {
      return completing
    },
    creating ({ creating }) {
      return creating
    },
    data ({ data }) {
      return get(data, 'data', [])
    },
    loading ({ loading }) {
      return loading
    },
    pagination ({ data }) {
      return {
        current: get(data, 'current_page', 1),
        last: get(data, 'last_page', 1),
        per: get(data, 'per_page', 15),
        total: get(data, 'total', 1),
        from: get(data, 'from', 1),
        to: get(data, 'to', 1)
      }
    }
  },
  mutations: {
    'fetchData/request' (state) {
      state.loading = true
      state.data = {}
    },
    'fetchData/success' (state, response) {
      state.loading = false
      state.data = response.data
    },
    'fetchData/failure' (state) {
      state.loading = false
      state.data = {}
    },
    'createCall/request' (state) {
      state.creating = true
    },
    'createCall/success' (state) {
      state.creating = false
    },
    'createCall/failure' (state) {
      state.creating = false
    },
    'completeCall/request' (state) {
      state.completing = true
    },
    'completeCall/success' (state) {
      state.completing = false
    },
    'completeCall/failure' (state) {
      state.completing = false
    }
  }
}
