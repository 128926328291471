<template>
  <div id="app">
    <CardNotifications>
      <transition name="fade" mode="out-in">
        <div class="align-items-center bg-primary d-flex flex-column justify-content-center vh-100" v-if="isLoading && !loaded">
          <b-spinner variant="light"></b-spinner>
        </div>
        <router-view v-if="!isLoading || loaded" />
      </transition>
    </CardNotifications>
  </div>
</template>

<script>
import CardNotifications from './components/CardNotifications'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    CardNotifications
  },
  created () {
    this.token()
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters('authentication', ['isLoading', 'xsrfTokenLoaded'])
  },
  methods: {
    ...mapActions('authentication', ['token'])
  },
  watch: {
    isLoading (val) {
      if (val) this.loaded = true
    },
    '$route' (val) {
      if (this.$route.meta && this.$route.meta.title) {
        document.title = `${this.$route.meta.title} | Aim 2 Learn CRM`
      } else {
        document.title = 'Aim 2 Learn CRM'
      }
    }
  }
}
</script>

<style lang="scss">
  $link-color: $secondary;
  @import "bootstrap";
  @import "~bootstrap-vue/dist/bootstrap-vue.css";

  body {
    background: lighten($light, 2.5%);
  }

  @include media-breakpoint-up(md) {
    .w-md-50 {
      width: 50% !important;
    }
  }

  .minw-0 {
    min-width: 0;
  }

  .bg-selected {
    background: lighten($secondary, 40%);
  }

  .back-button {
    margin-left: -100%;
    position: relative;
  }

  .modal-backdrop {
    background: $primary;
    opacity: 0.8;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 250ms;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .mpan-prepend {
    border: 1px solid #ced4da;
    border-right: 0;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    color: #495057;
    font-size: 2rem;
    padding: 0.8rem 1.2rem;
  }

  .mpan-top-row .form-control {
    border-bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .mpan-bottom-row .form-control  {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .b-avatar .b-avatar-img {
      background: #fff;
      img {
          object-fit: contain;
          padding: 0.3rem;
          border-radius: 0;
      }
  }
</style>
